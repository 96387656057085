"use client";
import React, { useState } from "react";
import { Roboto } from "next/font/google";
import Navbar from "./TopBar/Navbar";
import Header from "./TopBar/Header";
import Footer from "./Footer/Footer";

export const roboto_init = Roboto({
  subsets: ["latin"],
  display: "swap",
  variable: "--font-roboto",
  weight: ["100", "300", "400", "500", "700", "900"],
});

const LayoutWrapper = ({ NavLinks, children }) => {
  const [isLight, setisLight] = useState(true);
  return (
    <>
    <body className={`ffMainContent ${roboto_init.variable} ${isLight ? "" : "darkTheme"}`}>
      <Navbar setisLight={setisLight} NavLinks={NavLinks} />
      <Header NavLinks={NavLinks} />
      <div className={`ffMainContent`}>
        {children}
      </div>
      <Footer />
    </body>
    </>
  );
};

export default LayoutWrapper;
