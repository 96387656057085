'use client';
import React from 'react'
import "@/scss/header.scss";
import Link from "next/link";
import { usePathname  } from "next/navigation";

const DropDownSvg = () => {
    return (
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0592 11.1187L14.1842 6.9937C14.2611 6.9141 14.353 6.85062 14.4547 6.80694C14.5564 6.76327 14.6657 6.74028 14.7764 6.73932C14.887 6.73836 14.9968 6.75944 15.0992 6.80134C15.2016 6.84324 15.2946 6.90512 15.3729 6.98337C15.4511 7.06161 15.513 7.15465 15.5549 7.25707C15.5968 7.35948 15.6179 7.46921 15.6169 7.57986C15.616 7.69051 15.593 7.79986 15.5493 7.90153C15.5056 8.0032 15.4421 8.09516 15.3625 8.17203L10.6484 12.8862C10.4921 13.0424 10.2802 13.1302 10.0592 13.1302C9.83823 13.1302 9.62631 13.0424 9.47004 12.8862L4.75587 8.17203C4.67628 8.09516 4.61279 8.0032 4.56912 7.90153C4.52545 7.79986 4.50246 7.69051 4.5015 7.57986C4.50053 7.46921 4.52162 7.35948 4.56352 7.25707C4.60542 7.15465 4.6673 7.06161 4.74554 6.98337C4.82379 6.90512 4.91683 6.84324 5.01924 6.80134C5.12166 6.75944 5.23139 6.73836 5.34204 6.73932C5.45269 6.74028 5.56204 6.76327 5.66371 6.80694C5.76538 6.85062 5.85733 6.9141 5.93421 6.9937L10.0592 11.1187Z"
          fill="white"
        />
      </svg>
    );
  };
  
  const SubMenu = ({ data, router }) => {
    return (
      <>
        <ul className="ffNavbarNavListDropDown">
          {data?.map((item, i) => (
            <li key={i}>
              <Link href={item?.url_path} className={router == item?.url_path ? "active" : ""}>{item?.title}</Link>
            </li>
          ))}
        </ul>
      </>
    );
  };
export const Header = ({NavLinks}) => {
  const router = usePathname();
  return (
    <div className="ffNavbarSection">
        <div className="ffContainer">
          <nav className="ffNavbarNav">
            <div className="ffNavbarNavList">
              <ul>
                {NavLinks.map((link, key) => (
                  <li
                    key={key}
                    className={link?.childs?.length > 0 ? "dropDownOtr" : null}
                  >
                    <Link href={link?.url_path} className={router == link?.url_path ? "active" : ""}>
                      {link?.title}
                      {link?.childs?.length > 0 ? <DropDownSvg /> : null}
                    </Link>
                    {link?.childs?.length > 0 ? (
                      <SubMenu data={link.childs} router={router} />
                    ) : null}
                  </li>
                ))}
              </ul>
            </div>
            <div className="ffSocialNavList">
              <ul>
                <li>
                    <svg
                        width="33"
                        height="52"
                        viewBox="0 0 33 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M29.5 0H32.5L3 52H0L29.5 0Z" fill="white" />
                    </svg>
                </li>
                <li>
                    <Link href="https://www.facebook.com/femalefirst.official" target="_blank" aria-label="Facebook" title="Facebook">
                        <svg
                            width="17"
                            height="16"
                            viewBox="0 0 17 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <g clipPath="url(#clip0_10006_2458)">
                                <path
                                d="M16.5 8.04889C16.5 3.60361 12.9183 0 8.5 0C4.08172 0 0.5 3.60361 0.5 8.04889C0.5 12.0663 3.42547 15.3962 7.25 16V10.3755H5.21875V8.04889H7.25V6.27562C7.25 4.25837 8.44437 3.1441 10.2717 3.1441C11.147 3.1441 12.0625 3.3013 12.0625 3.3013V5.28208H11.0537C10.06 5.28208 9.75 5.90257 9.75 6.53909V8.04889H11.9687L11.6141 10.3755H9.75V16C13.5745 15.3962 16.5 12.0664 16.5 8.04889Z"
                                fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_10006_2458">
                                <rect
                                    width="16"
                                    height="16"
                                    fill="white"
                                    transform="translate(0.5)"
                                />
                                </clipPath>
                            </defs>
                        </svg>
                    </Link>
                </li>
                <li>
                    <Link href="https://twitter.com/FemaleFirst_UK" target="_blank" aria-label='Twitter' title="Twitter">
                        <svg
                        width="20"
                            height="16"
                            viewBox="0 0 20 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <path
                                d="M1.0415 0L7.60476 8.82646L1 15.9997H2.48648L8.26917 9.71833L12.9428 16H18L11.067 6.67868L17.2149 0H15.7284L10.403 5.784L6.09979 0H1.0415ZM3.22763 1.10125H5.55152L15.8128 14.8984H13.4896L3.22763 1.10125Z"
                                fill="white"
                            />
                        </svg>
                    </Link>
                </li>
                <li>
                    <Link href="https://www.instagram.com/femalefirst_uk/" target="_blank" aria-label='Instagram' title="Instagram">
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                            <g clipPath="url(#clip0_10006_2463)">
                                <path
                                d="M0 0V16H16V0H0ZM12.8 6.4H9.6V9.6H6.4V12.8H3.2V3.2H12.8V6.4Z"
                                fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_10006_2463">
                                <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
  )
}

export default Header;